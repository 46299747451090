import React from 'react';
import GameLevel from '../components/GameLevel';

const DivisionLevel = (props) => {
  const generateQuestion = (level) => {
    let divisor, dividend, quotient;

    if (level > 10 || level === 'Mixed') {
      // For mixed table (level 10 and above), generate random division questions
      divisor = Math.floor(Math.random() * 8) + 2; // 2 to 9 (single digit)
      quotient = Math.floor(Math.random() * 10) + 1; // 1 to 11 (keeping your original range)
      dividend = Math.floor(divisor * quotient); // Ensure dividend is an integer
    } else {
      // For specific tables, the divisor is fixed (the level)
      divisor = level;
      quotient = Math.floor(Math.random() * 10) + 1; // 1 to 10
      dividend = divisor * quotient;
    }
    
    return {
      question: `${dividend} ÷ ${Math.floor(divisor)}`,
      answer: Math.floor(quotient)
    };
  };

  const getLevelCompleteMessage = (level) => {
    return `You've mastered the ${level >= 10 || level === 'Mixed' ? 'mixed' : level} division table!`;
  };

  const getBackgroundColor = () => {
    return 'bg-gradient-to-b from-green-400 to-green-600';
  };

  return (
    <GameLevel
      {...props}
      gameType="division"
      generateQuestion={generateQuestion}
      getLevelCompleteMessage={getLevelCompleteMessage}
      getBackgroundColor={getBackgroundColor}
    />
  );
};

export default DivisionLevel;