import React from 'react';
import GameLevel from '../components/GameLevel';

const MultiplicationLevel = (props) => {
  const generateQuestion = (level) => {
    let fixedMultiplier, variableMultiplier;

    if (level >= 10) {
      // For mixed table (level 10 and above), both multipliers are random
      fixedMultiplier = Math.floor(Math.random() * 9) + 2; // 2 to 10
      variableMultiplier = Math.floor(Math.random() * 10) + 1; // 1 to 10
    } else {
      // For specific tables, one multiplier is fixed (the level)
      fixedMultiplier = level;
      variableMultiplier = Math.floor(Math.random() * 10) + 1; // 1 to 10
    }
    
    // Ensure both multipliers are valid numbers
    fixedMultiplier = isNaN(fixedMultiplier) ? 2 : fixedMultiplier;
    variableMultiplier = isNaN(variableMultiplier) ? 1 : variableMultiplier;
    
    return {
      question: `${fixedMultiplier} × ${variableMultiplier}`,
      answer: fixedMultiplier * variableMultiplier
    };
  };

  const getLevelCompleteMessage = (level) => {
    return `You've mastered the ${level >= 10 ? 'mixed' : level} multiplication table!`;
  };

  const getBackgroundColor = () => {
    return 'bg-gradient-to-b from-blue-400 to-blue-600';
  };

  return (
    <GameLevel
      {...props}
      gameType="multiplication"
      generateQuestion={generateQuestion}
      getLevelCompleteMessage={getLevelCompleteMessage}
      getBackgroundColor={getBackgroundColor}
    />
  );
};

export default MultiplicationLevel;