import React, { useState } from 'react';

const IntroScreen = ({ onStartGame, highScores }) => {
  const [selectedGame, setSelectedGame] = useState(null);
  const tables = [2, 3, 4, 5, 6, 7, 8, 9, 10, 'Mixed'];
  const levels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const handleGameSelection = (game) => {
    setSelectedGame(game);
  };

  const handleLevelSelection = (level) => {
    onStartGame(selectedGame, level);
  };

  if (!selectedGame) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-gray-100 to-gray-300 p-4">
        <div className="bg-white p-6 sm:p-8 rounded-2xl shadow-2xl text-center max-w-md w-full">
          <h1 className="text-3xl sm:text-4xl font-bold text-gray-800 mb-6">Math Balloon Pop</h1>
          <p className="text-lg sm:text-xl mb-6 text-gray-700">Choose a game:</p>
          <div className="grid grid-cols-2 gap-4 mb-6">
            <button
              className="bg-yellow-500 hover:bg-yellow-600 active:bg-yellow-700 text-white font-bold py-4 px-4 rounded-full text-lg sm:text-xl transition-colors duration-200 shadow-md hover:shadow-lg"
              onClick={() => handleGameSelection('addition')}
            >
              Addition
            </button>
            <button
              className="bg-purple-500 hover:bg-purple-600 active:bg-purple-700 text-white font-bold py-4 px-4 rounded-full text-lg sm:text-xl transition-colors duration-200 shadow-md hover:shadow-lg"
              onClick={() => handleGameSelection('subtraction')}
            >
              Subtraction
            </button>
            <button
              className="bg-blue-500 hover:bg-blue-600 active:bg-blue-700 text-white font-bold py-4 px-4 rounded-full text-lg sm:text-xl transition-colors duration-200 shadow-md hover:shadow-lg"
              onClick={() => handleGameSelection('multiplication')}
            >
              Multiplication
            </button>
            <button
              className="bg-green-500 hover:bg-green-600 active:bg-green-700 text-white font-bold py-4 px-4 rounded-full text-lg sm:text-xl transition-colors duration-200 shadow-md hover:shadow-lg"
              onClick={() => handleGameSelection('division')}
            >
              Division
            </button>
          </div>
          <div className="mt-6 text-left">
            <h2 className="text-xl font-semibold text-gray-700 mb-2">High Scores:</h2>
            <ul>
              <li className="text-gray-600">Addition: {highScores.addition}</li>
              <li className="text-gray-600">Subtraction: {highScores.subtraction}</li>
              <li className="text-gray-600">Multiplication: {highScores.multiplication}</li>
              <li className="text-gray-600">Division: {highScores.division}</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  const isTableGame = selectedGame === 'multiplication' || selectedGame === 'division';

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-purple-400 to-purple-600 p-4">
      <div className="bg-white p-6 sm:p-8 rounded-2xl shadow-2xl text-center max-w-md w-full">
        <h1 className="text-3xl sm:text-4xl font-bold text-purple-700 mb-6">
          {selectedGame.charAt(0).toUpperCase() + selectedGame.slice(1)} Balloon Pop
        </h1>
        <p className="text-lg sm:text-xl mb-6 text-gray-700">
          {isTableGame ? 'Choose a table to start:' : 'Choose a level to start:'}
        </p>
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 mb-6">
          {(isTableGame ? tables : levels).map((item, index) => (
            <button
              key={item}
              className={`${
                selectedGame === 'multiplication' ? 'bg-blue-500 hover:bg-blue-600 active:bg-blue-700' :
                selectedGame === 'division' ? 'bg-green-500 hover:bg-green-600 active:bg-green-700' :
                selectedGame === 'addition' ? 'bg-yellow-500 hover:bg-yellow-600 active:bg-yellow-700' :
                'bg-purple-500 hover:bg-purple-600 active:bg-purple-700'
              } text-white font-bold py-4 px-4 rounded-full text-lg sm:text-xl transition-colors duration-200 shadow-md hover:shadow-lg`}
              onClick={() => handleLevelSelection(isTableGame ? item : index + 1)}
            >
              {item}
            </button>
          ))}
        </div>
        <button
          className="bg-gray-500 hover:bg-gray-600 active:bg-gray-700 text-white font-bold py-2 px-4 rounded-full text-lg sm:text-xl transition-colors duration-200 shadow-md hover:shadow-lg"
          onClick={() => setSelectedGame(null)}
        >
          Back
        </button>
        <p className="text-lg font-semibold text-purple-700 mt-6">High Score: {highScores[selectedGame]}</p>
      </div>
    </div>
  );
};

export default IntroScreen;