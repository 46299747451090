import React, { useState, useEffect } from 'react';
import MultiplicationLevel from './screens/multiplication';
import DivisionLevel from './screens/division';
import IntroScreen from './screens/IntroScreen';
import AdditionLevel from './screens/addition';
import SubtractionLevel from './screens/subtraction';

function App() {
  const [showIntro, setShowIntro] = useState(true);
  const [currentLevel, setCurrentLevel] = useState(1);
  const [highScores, setHighScores] = useState({
    multiplication: 0,
    division: 0,
    addition: 0,
    subtraction: 0
  });
  const [selectedTable, setSelectedTable] = useState(null);
  const [gameMode, setGameMode] = useState(null);

  useEffect(() => {
    const storedHighScores = localStorage.getItem('highScores');
    if (storedHighScores) {
      setHighScores(JSON.parse(storedHighScores));
    }
  }, []);

  const handleStartGame = (mode, tableIndex) => {
    setGameMode(mode);
    setSelectedTable(tableIndex);
    setShowIntro(false);
    setCurrentLevel(tableIndex);
  };

  const handleExitGame = () => {
    setShowIntro(true);
    setCurrentLevel(1);
    setSelectedTable(null);
    setGameMode(null);
  };

  const handleLevelComplete = (level, score) => {
    console.log(`Level ${level} completed with score ${score}`);
    setCurrentLevel(level + 1);
    updateHighScore(gameMode, score);
  };

  const updateHighScore = (mode, newScore) => {
    if (newScore > highScores[mode]) {
      const updatedHighScores = { ...highScores, [mode]: newScore };
      setHighScores(updatedHighScores);
      localStorage.setItem('highScores', JSON.stringify(updatedHighScores));
    }
  };

  const renderGame = () => {
    const props = {
      initialLevel: currentLevel,
      onLevelComplete: handleLevelComplete,
      onExit: handleExitGame,
      highScore: highScores[gameMode],
      updateHighScore: (score) => updateHighScore(gameMode, score),
      selectedTable: selectedTable,
    };

    if (gameMode === 'multiplication') {
      return <MultiplicationLevel {...props} />;
    } else if (gameMode === 'division') {
      return <DivisionLevel {...props} />;
    // Add these new conditions
    } else if (gameMode === 'addition') {
      return <AdditionLevel {...props} />;
    } else if (gameMode === 'subtraction') {
      return <SubtractionLevel {...props} />;
    }
  };

  return (
    <div className="App">
      {showIntro ? (
        <IntroScreen onStartGame={handleStartGame} highScores={highScores} />
      ) : (
        renderGame()
      )}
    </div>
  );
}

export default App;