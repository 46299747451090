import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSpring, animated } from '@react-spring/web';
import Confetti from 'react-confetti';

const GameLevel = ({ 
  initialLevel = 1, 
  onLevelComplete = () => {}, 
  onExit, 
  highScore, 
  updateHighScore,
  gameType,
  generateQuestion,
  getLevelCompleteMessage,
  getBackgroundColor
}) => {
  const [level, setLevel] = useState(initialLevel);
  const [score, setScore] = useState(0);
  const [currentProblem, setCurrentProblem] = useState({ question: '', answer: 0 });
  const [balloons, setBalloons] = useState([]);
  const [startTime, setStartTime] = useState(Date.now());
  const [showLevelComplete, setShowLevelComplete] = useState(false);
  const [questionsPool, setQuestionsPool] = useState([]);
  const [masteredQuestions, setMasteredQuestions] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });
  const gameAreaRef = useRef(null);
  const questionBoxRef = useRef(null);

  const BASE_BALLOON_ANIMATION_DURATION = 5;
  const [balloonAnimationDuration, setBalloonAnimationDuration] = useState(BASE_BALLOON_ANIMATION_DURATION);

  const [answerFeedback, setAnswerFeedback] = useState(null);
  const [poppedBalloon, setPoppedBalloon] = useState(null);
  const [showGodzilla, setShowGodzilla] = useState(false);
  const [showKnife, setShowKnife] = useState(false);
  const [knifePosition, setKnifePosition] = useState({ x: 0, y: 0 });

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const generateQuestionsPool = useCallback((currentLevel) => {
    const newPool = [];
    for (let i = 0; i < 10; i++) {
      const { question, answer } = generateQuestion(currentLevel);
      newPool.push({
        question,
        answer,
        mastered: false,
        attempts: 0,
        correctAnswers: 0,
        needsReview: false
      });
    }
    setQuestionsPool(newPool);
    setMasteredQuestions(0);
  }, [generateQuestion]);

  const generateIncorrectAnswer = useCallback((correctAnswer) => {
    let incorrectAnswer;
    do {
      incorrectAnswer = Math.floor(Math.random() * 100) + 1;
    } while (incorrectAnswer === correctAnswer);
    return incorrectAnswer;
  }, []);

  const generateBalloons = useCallback((correctAnswer) => {
    const positions = windowSize.width < 400 ? [10, 35, 65, 90] : isMobile ? [12, 38, 62, 88] : [20, 40, 60, 80];
    const correctPosition = Math.floor(Math.random() * 4);
    const newBalloons = positions.map((x, i) => ({
      id: i,
      number: i === correctPosition ? correctAnswer : generateIncorrectAnswer(correctAnswer),
      x: x,
      y: 0,
      popped: false,
    }));
    setBalloons(newBalloons);
  }, [generateIncorrectAnswer, isMobile, windowSize.width]);

  const startNewQuestion = useCallback(() => {
    const unansweredQuestions = questionsPool.filter(q => !q.mastered);
    if (unansweredQuestions.length === 0) {
      setShowLevelComplete(true);
      onLevelComplete(level, score);
      return;
    }
    const randomIndex = Math.floor(Math.random() * unansweredQuestions.length);
    const selectedQuestion = unansweredQuestions[randomIndex];
    setCurrentProblem(selectedQuestion);
    generateBalloons(selectedQuestion.answer);
    setStartTime(Date.now());
    setIsTransitioning(false);
  }, [questionsPool, level, score, onLevelComplete, generateBalloons]);

  const resetForNextQuestion = useCallback(() => {
    setIsTransitioning(true);
    setTimeout(() => {
      startNewQuestion();
      setIsTransitioning(false);
    }, 300);
  }, [startNewQuestion]);

  useEffect(() => {
    if (!showLevelComplete) {
      if (questionsPool.length === 0) {
        generateQuestionsPool(level);
      } else {
        startNewQuestion();
      }
    }
  }, [level, showLevelComplete, questionsPool, generateQuestionsPool, startNewQuestion]);

  const knifeAnimation = useSpring({
    from: { opacity: 0, transform: 'translate(-50%, -50%) rotate(-45deg) scale(0.5)' },
    to: { opacity: showKnife ? 1 : 0, transform: showKnife ? 'translate(-50%, -50%) rotate(0deg) scale(1)' : 'translate(-50%, -50%) rotate(-45deg) scale(0.5)' },
    config: { tension: 300, friction: 10 },
  });

  const calculatePoints = useCallback((time, attempts, isRepeated, currentLevel) => {
    let points = 10;
    points -= Math.min(9, Math.floor(time));
    if (attempts === 0 && !isRepeated) {
      points += 5;
    }
    
    if (currentLevel >= 10) {
      const bonusPoints = Math.floor((currentLevel - 9) * 2);
      points += bonusPoints;
    }
    
    if (isRepeated) {
      points = Math.floor(points / 3);
    }
    
    return Math.max(1, points);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getRelativePosition = useCallback((x, y) => {
    if (gameAreaRef.current) {
      const rect = gameAreaRef.current.getBoundingClientRect();
      return {
        x: ((x - rect.left) / rect.width) * 100,
        y: ((rect.bottom - y) / rect.height) * 100,
      };
    }
    return { x: 0, y: 0 };
  }, []);

  const handleBalloonClick = useCallback((event, clickedBalloon) => {
    event.preventDefault();
    if (isTransitioning) return;
    
    const rect = event.target.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
    
    const { x, y } = getRelativePosition(centerX, centerY);
    setKnifePosition({ x, y });
    setShowKnife(true);

    setTimeout(() => {
      setShowKnife(false);
      
      setBalloons(prevBalloons => 
        prevBalloons.map(balloon => 
          balloon.id === clickedBalloon.id ? { ...balloon, popped: true } : balloon
        )
      );
      
      const answerTime = (Date.now() - startTime) / 1000;

      setQuestionsPool(prevPool => {
        let newMasteredCount = masteredQuestions; // Start with current count
        const newPool = prevPool.map(q => {
          if (q.question === currentProblem.question) {
            const updatedQuestion = { ...q, attempts: q.attempts + 1 };
            
            if (clickedBalloon.number === currentProblem.answer) {
              updatedQuestion.correctAnswers += 1;
              if (updatedQuestion.needsReview && updatedQuestion.correctAnswers >= 3) {
                if (!updatedQuestion.mastered) {
                  updatedQuestion.mastered = true;
                  newMasteredCount += 1; // Increment only if newly mastered
                }
                updatedQuestion.needsReview = false;
              } else if (!updatedQuestion.needsReview && updatedQuestion.correctAnswers === 1) {
                if (!updatedQuestion.mastered) {
                  updatedQuestion.mastered = true;
                  newMasteredCount += 1; // Increment only if newly mastered
                }
              }
            } else {
              updatedQuestion.needsReview = true;
              updatedQuestion.correctAnswers = 0;
              if (updatedQuestion.mastered) {
                updatedQuestion.mastered = false;
                newMasteredCount -= 1; // Decrement if it was previously mastered
              }
            }
            
            return updatedQuestion;
          }
          return q;
        });

        setMasteredQuestions(newMasteredCount);
        return newPool;
      });

      if (clickedBalloon.number === currentProblem.answer) {
        const currentQuestion = questionsPool.find(q => q.question === currentProblem.question);
        const pointsEarned = calculatePoints(answerTime, currentQuestion.attempts, currentQuestion.needsReview, level);
        const newScore = score + pointsEarned;
        setScore(newScore);
        
        if (newScore > highScore) {
          updateHighScore(newScore);
        }

        setAnswerFeedback({
          message: `Correct! +${pointsEarned} Points`,
          x: clickedBalloon.x,
          y: clickedBalloon.y,
          isCorrect: true
        });

        setPoppedBalloon(clickedBalloon);

        if (questionsPool.filter(q => q.mastered).length === 10) {
          setShowLevelComplete(true);
          onLevelComplete(level, newScore);
        } else {
          setIsTransitioning(true);
        }
      } else {
        const maxPoints = calculatePoints(0, 0, false, level);
        const penaltyPoints = maxPoints;
        const newScore = Math.max(0, score - penaltyPoints);
        setScore(newScore);
        setAnswerFeedback({
          message: `Wrong answer! -${penaltyPoints} Points`,
          x: clickedBalloon.x,
          y: clickedBalloon.y,
          isCorrect: false
        });
        setShowGodzilla(true);
      }
      
      setTimeout(() => {
        setAnswerFeedback(null);
        setShowGodzilla(false);
      }, 1000);
    }, 50);
  }, [
    isTransitioning, 
    getRelativePosition, 
    startTime, 
    questionsPool, 
    currentProblem, 
    score, 
    highScore, 
    updateHighScore, 
    level, 
    calculatePoints, 
    onLevelComplete,
    masteredQuestions // Add this to the dependency array
  ]);

  const calculateMaxHeight = useCallback(() => {
    if (gameAreaRef.current && questionBoxRef.current) {
      const gameAreaRect = gameAreaRef.current.getBoundingClientRect();
      const questionBoxRect = questionBoxRef.current.getBoundingClientRect();
      const maxHeight = (gameAreaRect.height - questionBoxRect.bottom - 60) / gameAreaRect.height * 100;
      return Math.max(40, maxHeight);
    }
    return 60;
  }, []);

  useEffect(() => {
    const maxHeight = calculateMaxHeight();
    const newDuration = (BASE_BALLOON_ANIMATION_DURATION * maxHeight) / 100;
    setBalloonAnimationDuration(newDuration);
  }, [calculateMaxHeight]);

  useEffect(() => {
    let animationFrameId;
    const maxHeight = calculateMaxHeight();
    
    const animateBalloons = () => {
      const currentTime = Date.now();
      const elapsedTime = (currentTime - startTime) / 1000;
      
      if (elapsedTime >= balloonAnimationDuration || isTransitioning) {
        cancelAnimationFrame(animationFrameId);
        resetForNextQuestion();
      } else {
        const progress = elapsedTime / balloonAnimationDuration;
        setBalloons(prevBalloons =>
          prevBalloons.map(balloon => ({
            ...balloon,
            y: Math.min(progress * 100, maxHeight),
          }))
        );
        animationFrameId = requestAnimationFrame(animateBalloons);
      }
    };
    
    animationFrameId = requestAnimationFrame(animateBalloons);
    
    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [startTime, balloonAnimationDuration, resetForNextQuestion, calculateMaxHeight, isTransitioning]);

  const handleNextLevel = useCallback(() => {
    setLevel(prevLevel => {
      if (prevLevel === 'Mixed') {
        return 11;
      } else {
        return prevLevel + 1;
      }
    });
    setShowLevelComplete(false);
    setMasteredQuestions(0);
    setQuestionsPool([]);
    setScore(0);
    setCurrentProblem({ question: '', answer: 0 });
    setBalloons([]);
    setIsTransitioning(false);
    setStartTime(Date.now());
    
    // Use setTimeout to ensure state updates have been applied before generating new questions
    setTimeout(() => {
      generateQuestionsPool(prevLevel => prevLevel === 'Mixed' ? 11 : prevLevel + 1);
    }, 0);
  }, [generateQuestionsPool]);

  if (showLevelComplete) {
    return (
      <div className="flex items-center justify-center h-screen bg-gradient-to-b from-sky-400 to-sky-600">
        <div className="bg-white p-8 rounded-lg shadow-lg text-center">
          <h2 className="text-3xl font-bold text-sky-700 mb-4">Level {level} Complete!</h2>
          <p className="text-xl mb-4">{getLevelCompleteMessage(level)}</p>
          <p className="text-lg mb-6">Your total score: {score}</p>
          <button 
            className="bg-sky-500 hover:bg-sky-600 text-white font-bold py-2 px-4 rounded"
            onClick={handleNextLevel}
          >
            Next Level
          </button>
        </div>
      </div>
    );
  }

  return (
    <div 
      ref={gameAreaRef}
      className={`fixed inset-0 ${getBackgroundColor()} overflow-hidden font-sans`}
    >
      {/* Top bar with level, question, scores, and exit button */}
      <div className="absolute top-0 left-0 right-0 flex flex-col sm:flex-row justify-between items-center p-2 bg-white bg-opacity-80">
        <div className="flex items-center w-full sm:w-auto justify-between sm:justify-start">
          <div className="text-lg font-semibold text-sky-600 mr-2">
            Level: {level === 'Mixed' ? 'Mixed' : level}
          </div>
          <button 
            className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-2 rounded shadow-md text-sm"
            onClick={onExit}
          >
            Exit
          </button>
        </div>
        
        {/* Question display */}
        <div 
          ref={questionBoxRef}
          className="my-2 sm:my-0 px-4 py-2 bg-sky-100 rounded-lg shadow-md"
        >
          <div className="text-xl sm:text-2xl md:text-3xl font-bold text-sky-700">
            {currentProblem.question}
          </div>
        </div>

        <div className="flex flex-col items-end">
          <div className="text-sm text-sky-600">Mastered: {masteredQuestions}/10</div>
          <div className="text-sm text-sky-600">Score: {score}</div>
          <div className="text-sm text-sky-600">High Score: {highScore}</div>
        </div>
      </div>

      {balloons.map((balloon) => (
        !balloon.popped && (
          <div
            key={balloon.id}
            className={`absolute w-16 h-16 sm:w-20 sm:h-20 md:w-24 md:h-24 rounded-full bg-gradient-to-b from-pink-400 to-pink-600 flex items-center justify-center cursor-pointer shadow-lg transition-transform hover:scale-110 active:scale-90 ${isTransitioning ? 'opacity-0' : 'opacity-100'} transition-opacity duration-300`}
            style={{
              left: `${balloon.x}%`,
              bottom: `${balloon.y}%`,
              transform: 'translate(-50%, 50%)',
            }}
            onClick={(event) => handleBalloonClick(event, balloon)}
          >
            <span className="text-white text-xl sm:text-2xl md:text-3xl font-bold">
              {isNaN(balloon.number) ? '?' : balloon.number}
            </span>
          </div>
        )
      ))}

      {answerFeedback && (
        <div
          className={`absolute px-2 py-1 rounded text-sm ${
            answerFeedback.isCorrect ? 'bg-green-500' : 'bg-red-500'
          } text-white`}
          style={{
            left: `${answerFeedback.x}%`,
            bottom: `${answerFeedback.y}%`,
            transform: 'translate(-50%, -100%)',
          }}
        >
          {answerFeedback.message}
        </div>
      )}

      <animated.div
        style={{
          ...knifeAnimation,
          position: 'absolute',
          left: `${knifePosition.x}%`,
          bottom: `${knifePosition.y}%`,
          transform: 'translate(-50%, 50%)',
          pointerEvents: 'none',
        }}
      >
        <span role="img" aria-label="knife" style={{ fontSize: '2rem' }}>🔪</span>
      </animated.div>

      {poppedBalloon && (
        <Confetti
          width={windowSize.width}
          height={windowSize.height}
          numberOfPieces={50}
          recycle={false}
          onConfettiComplete={() => setPoppedBalloon(null)}
          confettiSource={{
            x: windowSize.width * (poppedBalloon.x / 100),
            y: windowSize.height * (1 - poppedBalloon.y / 100),
            w: 0,
            h: 0,
          }}
          tweenDuration={500}
          gravity={0.6}
        />
      )}

      {showGodzilla && (
        <div className="fixed inset-0 flex items-center justify-center z-50 pointer-events-none">
          <div className="w-40 h-40 sm:w-48 sm:h-48 md:w-56 md:h-56">
            <img
              src="/godzilla.jpg"
              alt="Godzilla"
              className="w-full h-full object-cover rounded-full"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default GameLevel;