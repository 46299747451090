import React from 'react';
import GameLevel from '../components/GameLevel';

const AdditionLevel = (props) => {
  const generateQuestion = (level) => {
    let num1, num2;
    
    if (level <= 5) {
      // Levels 1-5: Single-digit addition, sum <= 10
      num1 = Math.floor(Math.random() * 5) + 1;
      num2 = Math.floor(Math.random() * (10 - num1)) + 1;
    } else if (level <= 10) {
      // Levels 6-10: Single-digit addition, sum <= 18
      num1 = Math.floor(Math.random() * 9) + 1;
      num2 = Math.floor(Math.random() * (18 - num1)) + 1;
    } else {
      // Levels 11+: Simple double-digit addition (tens + ones)
      num1 = Math.floor(Math.random() * 9) + 1;
      num2 = (Math.floor(Math.random() * 9) + 1) * 10;
    }

    return {
      question: `${num1} + ${num2}`,
      answer: num1 + num2
    };
  };

  const getLevelCompleteMessage = (level) => {
    if (level <= 5) {
      return `Great job! You're adding numbers up to 10!`;
    } else if (level <= 10) {
      return `Excellent! You can now add numbers up to 18!`;
    } else {
      return `Amazing! You're adding tens and ones!`;
    }
  };

  const getBackgroundColor = () => {
    return 'bg-gradient-to-b from-yellow-400 to-yellow-600';
  };

  return (
    <GameLevel
      {...props}
      gameType="addition"
      generateQuestion={generateQuestion}
      getLevelCompleteMessage={getLevelCompleteMessage}
      getBackgroundColor={getBackgroundColor}
    />
  );
};

export default AdditionLevel;