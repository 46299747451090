import React from 'react';
import GameLevel from '../components/GameLevel';

const SubtractionLevel = (props) => {
  const generateQuestion = (level) => {
    let num1, num2;
    
    if (level <= 5) {
      // Levels 1-5: Single-digit subtraction, result >= 0
      num1 = Math.floor(Math.random() * 5) + 5;
      num2 = Math.floor(Math.random() * num1);
    } else if (level <= 10) {
      // Levels 6-10: Single-digit subtraction, num1 <= 10
      num1 = Math.floor(Math.random() * 5) + 6;
      num2 = Math.floor(Math.random() * (num1 - 1)) + 1;
    } else {
      // Levels 11+: Simple double-digit subtraction (subtract ones from tens)
      num1 = (Math.floor(Math.random() * 9) + 1) * 10;
      num2 = Math.floor(Math.random() * 9) + 1;
    }

    return {
      question: `${num1} - ${num2}`,
      answer: num1 - num2
    };
  };

  const getLevelCompleteMessage = (level) => {
    if (level <= 5) {
      return `Great job! You're subtracting small numbers!`;
    } else if (level <= 10) {
      return `Excellent! You can now subtract from numbers up to 10!`;
    } else {
      return `Amazing! You're subtracting ones from tens!`;
    }
  };

  const getBackgroundColor = () => {
    return 'bg-gradient-to-b from-purple-400 to-purple-600';
  };

  return (
    <GameLevel
      {...props}
      gameType="subtraction"
      generateQuestion={generateQuestion}
      getLevelCompleteMessage={getLevelCompleteMessage}
      getBackgroundColor={getBackgroundColor}
    />
  );
};

export default SubtractionLevel;